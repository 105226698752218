import React from "react";
import { connect } from 'react-redux';
import { navigate } from "gatsby";
import i18n from '../i18n/i18n';
import {
    loginRestCall, setInitialState,
    setStateForAlert
} from '../actions/AppActions';
import { toast } from 'react-toastify';
import {
    Button, TextField
} from '@material-ui/core';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            openAlert: this.props.openAlert,
            alertText: this.props.alertText,
            alertTitle: this.props.alertTitle
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.logInFailed === true) {
            toast.error(i18n.t('wrong_credentials'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
            this.props.setInitialState('logInFailed')
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown)
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown)
    }
    handleKeyDown = (event) => {
        if (event.key === "Enter") {
            this.loginPressed()
        }
    }

    loginPressed() {
        if (this.state.username && this.state.password) {
            this.props.loginRestCall(this.state.username, this.state.password)
        } else {
            toast.error(i18n.t('username_password_required'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    render() {
        return (
            <div style={this.props.transition && this.props.transition.style}>
                <div style={{
                    textAlign: 'center',
                }}>
                    <h1>Login</h1>
                    <TextField
                        label={i18n.t('username')}
                        onChange={(event) => this.setState({ username: event.target.value })}
                    />
                    <br />
                    <TextField
                        type="password"
                        label={i18n.t('password')}
                        onChange={(event) => this.setState({ password: event.target.value })}
                    />
                    <br />
                    <Button variant="contained"
                        style={{ margin: 15 }}
                        onClick={() => this.loginPressed()}>
                        {i18n.t('login')}
                    </Button>
                    {/* <Alert></Alert> */}
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        logInFailed: state.appReducer.logInFailed,
        token: state.appReducer.token,
        openAlert: state.appReducer.openAlert,
        alertText: state.appReducer.alertText,
        alertTitle: state.appReducer.alertTitle
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (c) => dispatch(setInitialState(c)),
        loginRestCall: (username, password) => dispatch(loginRestCall(username, password)),
        setStateForAlert: (openAlert, alertText, alertTitle) => dispatch(setStateForAlert(openAlert, alertText, alertTitle))

    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);